import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Dökümanlar" }) => {
  return (
    <AnimationRevealPage>

      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h2>Eğitim Dökümanları</h2>
            <p>
              Eğitimlerimizle ilgili dökümanlarımıza aşağıdaki bağlantılardan ulaşabilirsiniz.
            </p>

            <h3>Ulaşım Bağlantıları</h3>
            <ul style={{marginTop:"2vh"}}>
              <li>
                <a target="_blank" href="https://drive.google.com/file/d/1qfWjm0fIyjFmkkH075gvqnAFuP9VF2yx/view?usp=drive_link">
                  Kaizen, Triz, 5S, Six Sigma ve Taguchi Metodları
                </a>
              </li> 
              <li>
                <a target="_blank" href="https://drive.google.com/file/d/1av6j9x_T9wxsUYwxUJ6_iEsomsahkbNf/view?usp=drive_link">
                  Temel STEM A ve Robotik
                </a>
              </li>
              <li><a target="_blank" href="https://drive.google.com/file/d/1pAyNFTUFUAHRldn_Z_NsNDc9CfhtI4Ai/view?usp=drive_link">İş Sağlığı ve Güvenliği</a>
              </li>
              <li>
                <a target="_blank" href="https://drive.google.com/file/d/120fLNtKOQfdVzjMkjX79UMBsQZpKZFhO/view?usp=sharing">Yenilenebilir Enerji</a>
              </li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>

    </AnimationRevealPage>
  );
};
