import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Duyurular" }) => {
  return (
    <AnimationRevealPage>

      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>


            <h2>"Kadınlar Yenilenebilir Enerji Bağlamında Geleceği Kodluyor" Projesi Eğitimi</h2>
            <p>
            Değerli KOBİ Sahipleri ve Çalışanları, 
            </p>
            <p>
            Kadınlar Yenilenebilir Enerji Bağlamında Geleceği Kodluyor" isimli projemiz kapsamında, beş KOBİ sahibi veya çalışanına ÜCRETSİZ ISO45001, ISO14001 ve ISO50001 standartları temel eğitimleri verilecektir. Bu eğitimler, insana yakışır çalışma koşullarını sağlamak ve şirketlerin sürdürülebilirlik standartlarını yükseltmek amacıyla düzenlenmektedir.
            </p>

            <h3 id="faaliyetler">Eğitim İçeriği</h3>
            <ul>
              <li>
                <p>
                ISO 45001:2018 İş Sağlığı ve Güvenliği Yönetim Sistemi Temel Eğitimi
                </p>
              </li>
              <li>
                <p>
                ISO 14001 Çevre Yönetim Sistemi Temel Eğitimi
                </p>
              </li>
              <li>
              ISO 50001:2018 Enerji Yönetim Sistemi Temel Eğitimi
              </li>
            </ul>
            <h3>Başvuru Koşulları</h3>
            <ul>
              <li>
                <p>
                Başvuru Belgeleri: SGK bildirgesini sunmaları gerekmektedir.
                </p>
              </li>
              <li>
                <p>
                Başvuru Formu: Amasya Üniversitesi'ndeki ofisimizden temin edilebilir.
                </p>
              </li>
            </ul>
            <h3>Başvuru Süresi</h3>
            <ul>
              <li>
                <p>
                Son başvuru tarihi 15 Ağustos. Bu tarihe kadar başvuruların ofisimize teslim edilmesi gerekmektedir.
                </p>
              </li>
            </ul>
            <h3>Başvuru Adresi</h3>
            <ul>
              <li><p>Amasya Üniversitesi Mühendislik Fakültesi A Blok Kat 3 Oda 323 Proje Ofisi. Lütfen gerekli belgelerle birlikte başvurunuzu zamanında yapınız.</p></li>
            </ul>
         
            <h3>İletişim</h3>
            <ul>
              <li>Telefon: +90 5465302368</li>
            </ul>

            <p>Sizleri aramızda görmekten mutluluk duyarız.
            "Kadınlar Yenilenebilir Enerji Bağlamında Geleceği Kodluyor" Projesi Ekibi</p>
        
          </Text>
        </ContentWithPaddingXl>
      </Container>

    </AnimationRevealPage>
  );
};
