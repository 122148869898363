import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Proje Hakkında" }) => {
  return (
    <AnimationRevealPage>

      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>


            <h2>Genel Bilgi</h2>
            <p>
            Projemiz, TR83'te güçlü bir Kodlama, STEM+A, Robotik, Drone ve Yenilenebilir Enerji kapasitesi geliştirerek TR83'te güçlü, insana yakışır iş koşullarının sağlanmasına katkıda bulunmaktadır. Eğitmen eğitimi faaliyetleriyle STEM+A ve Robotik alanlarında akademik kapasiteyi ve sürdürülebilirliği geliştireceğiz. Toplumumuzun savunmasız kesimini, çalışılmış bir programla eğiterek, onların istihdamını ve istihdam edilebilirliğini artırırken, işsiz kadınlara, atanamayan öğretmenlere, üniversite öğrencilerine ve engellilere hedef grup olarak öncelik veriyoruz. 
            </p>
            <p>
            İşverenlere ve STK yöneticilerine eğitim vererek, toplumsal cinsiyet eşitliği ve çevresel kaygılarla insana yakışır iş koşullarını güçlendiriyor ve KOBİ'lere yönelik standardizasyon faaliyetleriyle bunun sürdürülebilir olmasına yardımcı oluyoruz.
            </p>

            <h2 id="faaliyetler">Faaliyetler</h2>
            <h3>HAZIRLIK FAALİYETLERİ</h3>
            <ul>
              <li>
                <p>
                1.1 Ekibin oluşturulması
                </p>
              </li>
              <li>
                <p>
                1.2 Proje Ofisinin Hazırlanması
                </p>
              </li>
              <li>
              1.3 Görünürlük faaliyetleri: Görünürlük materyallerinin hazırlanması, yayınlanması ve kullanılması
              </li>
            </ul>
            <h3>UYGULAMA FAALİYETLERİ</h3>
            <ul>
              <li>
                <p>
                2.1 Eğitim ve Öğretim Faaliyetleri
                </p>
              </li>
              <li>
                <p>
                2.1.1 Hedef Grup 1'e Yönelik Eğitim ve Öğretim Faaliyetleri
                </p>
              </li>
              <li>
              2.1.2 Hedef Grup 2'ye Yönelik Eğitim ve Öğretim Faaliyetleri
              </li>
              <li>
              2.1.3 Hedef Grup 3'e Yönelik Eğitim ve Öğretim Faaliyetleri
              </li>
              <li>
              2.1.4 Hedef Grup 4'e Yönelik Eğitim ve Öğretim Faaliyetleri
              </li>
              <li>
              2.1.5 STK'lara ve KOBİ'lere yönelik eğitim faaliyetleri
              </li>
              <li>
              2.1.6 KOBİ Sahiplerine Yönelik Eğitimler
              </li>
              <li>
              2.2. Belgelendirme ve Standardizasyon faaliyetleri.
              </li>
              <li>
              2.3 Seminer/Konferans Faaliyetleri
              </li>
              <li>
              2.3.1 Seminerler
              </li>
              <li>
              2.3.2 Farkındalık Günleri
              </li>
              <li>
              2.4 Teknik Geziler
              </li>
              <li>
              2.4.1 Yerel Teknik Geziler
              </li>
              <li>
              2.4.2 AB'deki en iyi uygulamaların incelenmesine yönelik Teknik Gezi
              </li>
              <li>
              2.5 Yayın/Dokümantasyon Faaliyeti
              </li>
              <li>
              2.6 Yaygınlaştırma ve çoğaltma faaliyetleri
              </li>
              <li>
              2.7 Amasya Üniversitesinde İyi Donanımlı STEM+A, Robotik ve Yenilenebilir Enerji Laboratuvarları
              </li>
              <li>
              2.8 Sertifikasyon Faaliyetleri
              </li>
            </ul>
            <h3>GÖRÜNÜRLÜK FAALİYETLERİ</h3>
            <ul>
              <li>
                <p>
                4. Raporlama Faaliyetleri
                </p>
              </li>
              <li>
                <p>
                4.1 Ara Sınav ve Final Raporları
                </p>
              </li>
            </ul>
            <h2 id="egitimler">EĞİTİMLER</h2>

            <h3>Robotik Kodlama Programı</h3>
            <ul>
              <li>Temel Robotik ve Kodlama</li> 
              <li>Blok Tabanlı Robotik Kodlama</li> 
              <li>Atölyeler</li>{" "}
              <li>İş Sağlığı ve Güvenliği</li> 
              <li>Temel İlk Yardım Eğitimi</li>
              <li>Eğitmen Eğitimi</li>
              <li>İnovasyon Sanatı ve STEM+A</li>
            </ul>
            <h3>Yenilenebilir Enerji Teknolojileri Programı</h3>
            <ul>
              <li>Yenilenebilir Enerji Teknolojileri</li>
              <li>Temel İlk Yardım Eğitimi</li>
              <li>Girişimcilik</li>
              <li>AutoCAD</li>
              <li>PLC/SCADA</li>
              <li>İş Sağlığı ve Güvenliği Kursu</li>
              <li>Enerji verimliliği</li>
              <li>KAIZEN, TRIZ, 5S, Altı Sigma ve Taguchi Yöntemleri</li>
            </ul>
            <h3>PLC/SCADA ve AutoCAD Programı</h3>
            <ul> <li>Programlanabilir Lojik Kontrol (PLC)</li>
            <li>3D baskı, kontrol teknolojisi. İHA Drone ve Nesnelerin İnterneti</li>
            <li>Temel İlk Yardım Eğitimi</li>
            <li>Girişimcilik</li>
            <li>AutoCAD</li>
            <li>PLC/SCADA</li>
            <li>İş Sağlığı ve Güvenliği Kursu</li>
            <li>Enerji verimliliği</li>
            <li>KAIZEN, TRIZ, 5S, Altı Sigma ve Taguchi Yöntemleri</li>
            </ul>
           <h3>STK Programı</h3>
           <ul>
            <li>STK’ların Yönetim Politikaları</li>
            <li>Sosyal İçerme, İletişim ve Temel Medya Kullanımı</li>
           </ul>
           <h3>İşveren Programı</h3>
            <ul>    
            <li>İnsana Yakışır Çalışma Koşulları ve Ergonomi</li>
            <li>Sertifikalar, Kurumsal ve Standardizasyonlar</li>
            <li>İşyerlerinde Çalışanlara Mesleki Afetler ve Hijyen Eğitimi</li>
            </ul>
            <h3>Engelli Bireyler Eğitim Programı</h3>
            <ul>
              <li>Sosyal İçerme, İletişim ve Temel Medya Kullanımı + Yenilenebilir Enerji Teknolojileri Programı</li>
            </ul>
            <h3>Sertifika Eğitim</h3>
            <ul>
              <li>ISO45001</li>
              <li>ISO14001</li>
              <li>ISO50001</li>
            </ul>
        
          </Text>
        </ContentWithPaddingXl>
      </Container>

    </AnimationRevealPage>
  );
};
