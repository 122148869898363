import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/Hero.js";
import Features from "components/features/Features.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/Testimonial.js";
import FAQ from "components/faqs/FAQ.js";
import Footer from "components/footers/Footer.js";
import serverRedundancyIllustrationImageSrc from "images/design-illustration-2.svg"
import serverSecureIllustrationImageSrc from "images/prototype-illustration.svg"
import Application from "pages/Application";


export default () => {
  return (
    <AnimationRevealPage>
      {/* <Hero /> */}

      <Application />
      <Features />
    
      <MainFeature 
        subheading="Ücretsiz"
        heading="Sertifika programı ile şansını artır"
        imageSrc={serverRedundancyIllustrationImageSrc}
        buttonRounded={false}
        description="Henüz atanmamış öğretmenler, İŞKUR'a başvurmuş işsiz kadınlar, işini kaybetme ihtimali olan kadınlar, teknik bölümlerde okuyan üniversite öğrencileri, dezavantajlı gruplar için eşsiz bir fırsat."
      />
      <MainFeature 
        subheading="Ücretsiz"
        heading="Eğitim programı ile ekibini geliştir"
        imageSrc={serverSecureIllustrationImageSrc}
        buttonRounded={false}
        textOnLeft={false}
        description="Sivil Toplum Kuruluşları yöneticileri, İşverenler, Küçük ve Orta ölçekli işletme sahipleri için yeni çalışan adayları."
      />
  

      {/* <Testimonial />
      <FAQ /> */}
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
}
